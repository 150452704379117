<template>
  <b-modal
      id="form-newsletter-test-send-modal"
      ref="form-newsletter-test-send-modal"
      cancel-variant="outline-secondary"
      ok-title="Próbaküldés"
      cancel-title="Mégsem"
      @ok="handleOk"
      centered
      title="Próbaküldés"
  >
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
                label="Email cím"
            >
              <validation-provider
                  #default="{ errors }"
                  name="email cím"
                  rules="required|email"
                  vid="email"
              >
                <b-form-input
                    v-model="formData.email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="teszt@maconka.hu"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import vSelect from 'vue-select'
import BCardCode from "@core/components/b-card-code";

export default{
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      role: 'newsletter',
      formData: {
        id: '',
        email: '',
      },
      required,
      email,
    }
  },
  created() {
    this.formData.id = this.id
    this.formData.email = this.$store.getters.user.email
  },
  methods: {
    handleOk() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))

          this.$store.dispatch('newsletterTestSend', fd).then(() => {
            this.$helpers.showSuccessToast()
            this.$emit('test-sent')
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
  },
}
</script>
